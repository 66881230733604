<template>
  <v-row>
    <v-col cols="12">
      <template v-if="stateCreated === true">
        <v-alert
          dismissible
          type="success"
          :close="stateCreated = false"
        >
          State created successfully
        </v-alert>
      </template>
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <new-state />
          </div>
        </v-card-title>
        <v-card-title>
          States
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="allStates"
          item-key="name"
          show-select
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="removeState(item._id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.badge="{item}">
            <template v-if="item.badge === 'Active'">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  mdi-check
                </v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  mdi-close
                </v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import NewState from './NewState'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'States',
    },
    components: {
      NewState,
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'State',
            align: '',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Active',
            align: '',
            sortable: false,
            value: 'status',
          },
          {
            text: 'Action',
            value: 'action',
          },
        ],
      }
    },
    created () {
      this.$store.dispatch('getAllStatesAdmin')
    },
    computed: {
      ...mapGetters(['allStates', 'stateCreated']),
    },
    methods: {
      ...mapActions(['removeState']),
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
